<template>
  <div class="addTravelSpecs">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{ lineName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="套餐名称">
        <el-input
          style="width:500px"
          v-model.trim="formList.specName"
          placeholder="请输入套餐名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select
          v-model="formList.sellWay"
          placeholder="请选择"
          :disabled="
            formList.sellType === '2' ||
            formList.sellType === 2 ||
            sellType == '2' ||
            sellType == 2
          "
        >
          <el-option
            v-for="item in sellWayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
      
        label="所需要的积分"
        v-if="
          formList.sellWay == 1 || sellType == '2' || sellType == 2
            ? false
            : true
        "
      >
        <el-input
        style="width:500px"
          v-model.number="formList.integral"
          placeholder="请输入积分"
        ></el-input>
      </el-form-item>
      <el-form-item label="入住客房天数">
        <el-select v-model="formList.roomDays" placeholder="请选择入住客房天数">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="room"
        label="第一晚客房"
        v-if="formList.roomDays == 1 || formList.roomDays > 1"
      >
        <el-autocomplete
          class="inline-input"
          v-model="state1"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="state2"
          :fetch-suggestions="querySearch1"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
      </el-form-item>
      <el-form-item
        class="room"
        label="第二晚客房"
        v-if="formList.roomDays == 2 || formList.roomDays > 2"
      >
        <el-autocomplete
          class="inline-input"
          v-model="value1"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="value2"
          :fetch-suggestions="querySearch2"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-button type="primary" @click="onFollow(2)">同上</el-button>
      </el-form-item>
      <el-form-item
        class="room"
        label="第三晚客房"
        v-if="formList.roomDays == 3 || formList.roomDays > 3"
      >
        <el-autocomplete
          class="inline-input"
          v-model="value3"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="value4"
          :fetch-suggestions="querySearch3"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-button type="primary" @click="onFollow(3)">同上</el-button>
      </el-form-item>

      <el-form-item
        class="room"
        label="第四晚客房"
        v-if="formList.roomDays == 4 || formList.roomDays > 4"
      >
        <el-autocomplete
          class="inline-input"
          v-model="value5"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="value6"
          :fetch-suggestions="querySearch4"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-button type="primary" @click="onFollow(4)">同上</el-button>
      </el-form-item>
      <el-form-item
        class="room"
        label="第五晚客房"
        v-if="formList.roomDays == 5 || formList.roomDays > 5"
      >
        <el-autocomplete
          class="inline-input"
          v-model="value7"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="value8"
          :fetch-suggestions="querySearch5"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-button type="primary" @click="onFollow(5)">同上</el-button>
      </el-form-item>
      <el-form-item
        class="room"
        label="第六晚客房"
        v-if="formList.roomDays == 6 || formList.roomDays > 6"
      >
        <el-autocomplete
          class="inline-input"
          v-model="value9"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-autocomplete
          class="inline-input"
          v-model="value10"
          :fetch-suggestions="querySearch6"
          placeholder="请输入内容"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i
        ></el-autocomplete>
        <el-button type="primary" @click="onFollow(6)">同上</el-button>
      </el-form-item>
      <el-form-item label="每晚所需房间">
        <el-input-number v-model="formList.roomCount" :min="0" :max="10000" label="请输入数量"></el-input-number>


      </el-form-item>
      <el-form-item label="套餐说明">
        <el-input
          class="textArea"
          type="textarea"
          rows="6"
          v-model="formList.remark"
          placeholder="请输入套餐说明"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import { lineDetails } from "../../api/lineList";
import { hotelList } from "../../api/hotelList";
import { selectAllByHotelId } from "../../api/room";
import { addTravelSpec, travelSpecDetails } from "../../api/travelSpecs";
import { getHotelDetails } from "../../api/hotelList";
import { roomDetails } from "../../api/room";
export default {
  name: "addTravelSpecs",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    addOrEdit: {
      type: [Number, String],
      required: true,
    },
    sellType: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      state1: "",
      state2: "",
      lineName: "",
      hotelValue: "",
      restaurants: [],
      restaurant1: [],
      value1: "",
      value2: "",
      hotel2: [],
      room2: [],
      value3: "",
      value4: "",
      hotel3: [],
      room3: [],
      value5: "",
      value6: "",
      hotel4: [],
      room4: [],
      value7: "",
      value8: "",
      hotel5: [],
      room5: [],
      value9: "",
      value10: "",
      hotel6: [],
      room6: [],
      options: [
        {
          value: 0,
          label: "零天",
        },
        {
          value: 1,
          label: "一天",
        },
        {
          value: 2,
          label: "两天",
        },
        {
          value: 3,
          label: "三天",
        },
        {
          value: 4,
          label: "四天",
        },
        {
          value: 5,
          label: "五天",
        },
        {
          value: 6,
          label: "六天",
        },
      ],
      formList: {
        specsId: Number(this.addOrEdit),
        travelId: Number(this.id),
        specName: "",
        remark: "",
        sellWay: null,
        integral: null,
        roomDays: null,
        roomId1: null,
        roomId2: null,
        roomId3: null,
        roomId4: null,
        roomId5: null,
        roomId6: null,
        sellType: null,
        roomCount:0
      },
      sellWayList: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
    };
  },
  watch: {
    state1: async function () {
      var obj = this.restaurants.filter((item) => {
        return item.hotelName == this.state1;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.restaurants1 = data.data;
    },
    state2: function () {
      var obj = this.restaurants1.filter((item) => {
        return item.cateName === this.state2;
      });
      this.formList.roomId1 = obj[0].cateId;
    },
    value1: async function () {
      var obj = this.hotel2.filter((item) => {
        return item.hotelName == this.value1;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.room2 = data.data;
    },
    value2: function () {
      var obj = this.room2.filter((item) => {
        return item.cateName === this.value2;
      });
      this.formList.roomId2 = obj[0].cateId;
    },
    value3: async function () {
      var obj = this.hotel3.filter((item) => {
        return item.hotelName == this.value3;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.room3 = data.data;
    },
    value4: function () {
      var obj = this.room3.filter((item) => {
        return item.cateName === this.value4;
      });
      this.formList.roomId3 = obj[0].cateId;
    },
    value5: async function () {
      var obj = this.hotel4.filter((item) => {
        return item.hotelName == this.value5;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.room4 = data.data;
    },
    value6: function () {
      var obj = this.room4.filter((item) => {
        return item.cateName === this.value6;
      });
      this.formList.roomId4 = obj[0].cateId;
    },
    value7: async function () {
      var obj = this.hotel5.filter((item) => {
        return item.hotelName == this.value7;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.room5 = data.data;
    },
    value8: function () {
      var obj = this.room5.filter((item) => {
        return item.cateName === this.value8;
      });
      this.formList.roomId5 = obj[0].cateId;
    },
    value9: async function () {
      var obj = this.hotel6.filter((item) => {
        return item.hotelName == this.value9;
      });
      var formData = new FormData();
      formData.append("hotelId", obj[0].hotelId);
      const { data } = await selectAllByHotelId(formData);
      data.data.map((item) => {
        item.value = item.cateName;
      });
      this.room6 = data.data;
    },
    value10: function () {
      var obj = this.room6.filter((item) => {
        return item.cateName === this.value10;
      });
      this.formList.roomId6 = obj[0].cateId;
    },
  },
  created() {
    this.getLineName();
    this.getHotelList();
    if (Number(this.addOrEdit) !== 0) {
      this.gettravelSpecDetails();
    } else {
      if (Number(this.sellType) == 2) {
        this.formList.sellWay = 1;
      }
    }
  },
  methods: {
    async gettravelSpecDetails() {
      const { data } = await travelSpecDetails({
        specsId: Number(this.addOrEdit),
      });
      if (data.data[0].sellType === 2) {
        this.formList.sellWay = 1;
      } else {
        this.formList.sellWay = data.data[0].sellWay;
      }
      this.formList.sellType = data.data[0].sellType;
      this.formList.integral = data.data[0].integral;
      this.formList.specName = data.data[0].specName;
      this.formList.roomDays = data.data[0].roomDays;
      this.formList.remark = data.data[0].remark;
      this.formList.roomCount = data.data[0].roomCount;

      if (data.data[0].hotelId1) {
        const hotel1 = await getHotelDetails({
          hotelId: data.data[0].hotelId1,
        });
        this.state1 = hotel1.data.data[0].hotelName;
        // this.formList.hotelId1 = data.data[0].hotelId1;
        this.formList.roomId1 = data.data[0].roomId1;
      }
      if (data.data[0].hotelId2) {
        const hotel2 = await getHotelDetails({
          hotelId: data.data[0].hotelId2,
        });
        this.value1 = hotel2.data.data[0].hotelName;
        // this.formList.roomId2 = data.data[0].hotelId2;
        this.formList.roomId2 = data.data[0].roomId2;
      }
      if (data.data[0].hotelId3) {
        const hotel3 = await getHotelDetails({
          hotelId: data.data[0].hotelId3,
        });
        this.value3 = hotel3.data.data[0].hotelName;
        // this.formList.roomId3 = data.data[0].hotelId3;
        this.formList.roomId3 = data.data[0].roomId3;
      }
      if (data.data[0].hotelId4) {
        const hotel4 = await getHotelDetails({
          hotelId: data.data[0].hotelId4,
        });
        this.value5 = hotel4.data.data[0].hotelName;
        // this.formList.roomId4 = data.data[0].hotelId4;
        this.formList.roomId4 = data.data[0].roomId4;
      }
      if (data.data[0].hotelId5) {
        const hotel5 = await getHotelDetails({
          hotelId: data.data[0].hotelId5,
        });
        this.value7 = hotel5.data.data[0].hotelName;
        // this.formList.roomId5 = data.data[0].hotelId5;
        this.formList.roomId5 = data.data[0].roomId5;
      }
      if (data.data[0].hotelId6) {
        const hotel6 = await getHotelDetails({
          hotelId: data.data[0].hotelId6,
        });
        this.value9 = hotel6.data.data[0].hotelName;
        // this.formList.roomId6 = data.data[0].hotelId6;
        this.formList.roomId6 = data.data[0].roomId6;
      }
      if (data.data[0].roomId1) {
        const room1 = await roomDetails({
          roomId: data.data[0].roomId1,
        });
        this.state2 = room1.data.data[0].roomName;
      }
      if (data.data[0].roomId2) {
        const room2 = await roomDetails({
          roomId: data.data[0].roomId2,
        });
        this.value2 = room2.data.data[0].roomName;
      }
      if (data.data[0].roomId3) {
        const room3 = await roomDetails({
          roomId: data.data[0].roomId3,
        });
        this.value4 = room3.data.data[0].roomName;
      }
      if (data.data[0].roomId4) {
        const room4 = await roomDetails({
          roomId: data.data[0].roomId4,
        });
        this.value6 = room4.data.data[0].roomName;
      }
      if (data.data[0].roomId5) {
        const room5 = await roomDetails({
          roomId: data.data[0].roomId5,
        });
        this.value8 = room5.data.data[0].roomName;
      }
      if (data.data[0].roomId6) {
        const room6 = await roomDetails({
          roomId: data.data[0].roomId6,
        });
        this.value10 = room6.data.data[0].roomName;
      }
    },
    onFollow(value) {
      if (value == 2) {
        this.value1 = this.state1;
        this.value2 = this.state2;
        this.formList.roomId2 = this.formList.roomId1;
      } else if (value == 3) {
        this.value3 = this.value1;
        this.value4 = this.value2;
        this.formList.roomId3 = this.formList.roomId2;
      } else if (value == 4) {
        this.value5 = this.value3;
        this.value6 = this.value4;
        this.formList.roomId4 = this.formList.roomId3;
      } else if (value == 5) {
        this.value7 = this.value5;
        this.value8 = this.value6;
        this.formList.roomId5 = this.formList.roomId4;
      } else if (value == 6) {
        this.value9 = this.value7;
        this.value10 = this.value8;
        this.formList.roomId6 = this.formList.roomId5;
      }
    },
    async onSave() {
      if (
        this.formList.sellWay > 1 &&
        (this.formList.integral === null || this.formList.integral === "")
      ) {
        return this.$message({
          message: "在全积分和积分+金额情况下,积分为必填项",
          type: "error",
        });
      }
      if (
        this.formList.specName === "" ||
        this.formList.roomDays === "" ||
        this.formList.roomDays === null
      ) {
        this.$message({
          type: "error",
          message: "请把必填信息填写完整",
        });
        return;
      }
      if (this.formList.roomDays === 1 && this.formList.roomId1 === null) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      if (
        this.formList.roomDays === 2 &&
        (this.formList.roomId1 === null || this.formList.roomId2 === null)
      ) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      if (
        this.formList.roomDays === 3 &&
        (this.formList.roomId1 === null ||
          this.formList.roomId2 === null ||
          this.formList.roomId3 === null)
      ) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      if (
        this.formList.roomDays === 4 &&
        (this.formList.roomId1 === null ||
          this.formList.roomId2 === null ||
          this.formList.roomId3 === null ||
          this.formList.roomId4 === null)
      ) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      if (
        this.formList.roomDays === 5 &&
        (this.formList.roomId1 === null ||
          this.formList.roomId2 === null ||
          this.formList.roomId3 === null ||
          this.formList.roomId4 === null ||
          this.formList.roomId5 === null)
      ) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      if (
        this.formList.roomDays === 6 &&
        (this.formList.roomId1 === null ||
          this.formList.roomId2 === null ||
          this.formList.roomId3 === null ||
          this.formList.roomId4 === null ||
          this.formList.roomId5 === null ||
          this.formList.roomId6 === null)
      ) {
        this.$message({
          type: "error",
          message: "请选择客房",
        });
        return;
      }
      var arr = [
        this.formList.roomId1,
        this.formList.roomId2,
        this.formList.roomId3,
        this.formList.roomId4,
        this.formList.roomId5,
        this.formList.roomId6,
      ];
      var newArr = arr.filter((item) => {
        return item !== null;
      });

      //this.formList.roomCount = Array.from(new Set(newArr)).length;
      const { data } = await addTravelSpec(this.formList);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "添加套餐成功",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "添加套餐失败",
        });
      }
    },
    //获取酒店列表
    async getHotelList() {
      const { data } = await hotelList({
        currentPage: 1,
        pageSize: 10000,
      });
      data.list.map((item) => {
        item.value = item.hotelName;
      });
      this.restaurants = data.list;
      this.hotel2 = data.list;
      this.hotel3 = data.list;
      this.hotel4 = data.list;
      this.hotel5 = data.list;
      this.hotel6 = data.list;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch1(queryString, cb) {
      var restaurants = this.restaurants1;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch2(queryString, cb) {
      var restaurants = this.room2;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch3(queryString, cb) {
      var restaurants = this.room3;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch4(queryString, cb) {
      var restaurants = this.room4;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch5(queryString, cb) {
      var restaurants = this.room5;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    querySearch6(queryString, cb) {
      var restaurants = this.room6;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    handleSelect(value) {},
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    async getLineName() {
      const { data } = await lineDetails({
        travelId: Number(this.id),
      });
      this.lineName = data.data[0].travelName;
    },
  },
};
</script>
<style lang="less" scoped>
.addTravelSpecs {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    .room {
      .el-form-item__content {
        display: flex;
        .el-autocomplete {
          width: 250px !important;
          .el-input {
            width: 250px !important;
          }
        }
      }
    }
    .el-form-item {
      .el-form-item__label {
        width: 100px !important;
      }
      .el-form-item__content {
        .el-button {
          margin-left: 20px;
        }
        .textArea {
          width: 500px;
        }
        .el-input {
          //width: 500px;
        }
      }
    }
  }
}
</style>